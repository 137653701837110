export const handleErrorLog = ({
  msg,
  error,
  is_warning = false,
  additional_info = {},
}) => {
  if (is_warning) {
    console.warn(msg, { ...additional_info, error });
    return;
  }
  const utcDate = new Date().toUTCString();
  console.error(":::::::::::::: " + utcDate + " ::::::::::::::");
  console.error(msg, { ...additional_info, error });
};
