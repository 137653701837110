import { keys } from "../keys";
import { routes } from "../routes";
import { constants } from "../constants";
import { handleErrorLog } from "../error_logs";
import { metaInfo, staticMeta } from "./meta_info";
import { getBlogPageMeta, getCMSPageMeta } from "@/lib/services/cmsPage";
import { getCategoryMeta, getProductMeta } from "@/lib/services/product";
import {
  getHomePage,
  getOrgnizationInfo,
  getContentfulImgData,
} from "@/lib/services/contentful";

const capitalizeFirstLetter = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const getMetaImages = async (altText) => {
  try {
    const { data } = await getHomePage();
    if (data?.items?.length > 0 && data.items[0]?.fields?.seo?.fields) {
      const { imageUrl } = getContentfulImgData(
        data.items[0]?.fields?.seo?.fields.shareImage,
      );

      if (imageUrl) {
        return {
          images: {
            url: imageUrl,
            alt: altText,
          },
        };
      }
    }
  } catch (error) {
    handleErrorLog({
      error,
      additional_info: { altText },
      msg: "Error while generating homepage image metadata",
    });
  }
  return {};
};

const formatToInternationalUKPhoneNumber = (phoneNumber) => {
  try {
    // Remove any spaces for consistency
    const cleaned = phoneNumber?.replace(/\s+/g, "");

    // If the number is already in international format with +44
    if (/^\+44\d{10}$/.test(cleaned)) {
      // Add spaces for readability
      return cleaned?.replace(/^\+44(\d{3})(\d{3})(\d{4})$/, "+44 $1 $2 $3");
    }

    // If the number starts with 0, replace the leading 0 with +44 and format it
    if (/^0\d{10}$/.test(cleaned)) {
      return cleaned?.replace(/^0(\d{3})(\d{3})(\d{4})$/, "+44 $1 $2 $3");
    }
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { phoneNumber },
      msg: "Error while format to international UK Phone Number",
    });
  }

  // Return the original input if it doesn't match expected formats
  return phoneNumber;
};

const updateMetaObject = (metaObj, res, url, shareImages) => {
  metaObj.title = res?.meta_title || metaObj?.title;
  metaObj.description = res?.meta_description || "";
  metaObj.keywords = res?.meta_keywords || res?.meta_keyword || "";
  metaObj.openGraph = {
    url,
    title: metaObj.title,
    description: metaObj?.description,
    ...shareImages,
  };

  return metaObj;
};

const updateProductMeta = async (productSKU, metaData, currentURL) => {
  const { data, error } = await getProductMeta({ productSKU });
  if (error) {
    handleErrorLog({
      error,
      additional_info: { productSKU, currentURL },
      msg: "Error while fetching product metadata",
    });
  } else {
    let metaRes = data?.products?.items?.[0];
    let productImage = {};
    if (metaRes) {
      const productMeta = { ...metaInfo.product };
      let title = metaRes?.name;
      if (metaRes?.extraVariable?.brand_info?.length > 0) {
        title = `${metaRes?.extraVariable?.brand_info[0]?.title?.toUpperCase()} ${title}`;
      }

      metaRes.meta_title = productMeta.meta_title.replace("{{ TITLE }}", title);
      metaRes.meta_description = productMeta.meta_description.replace(
        "{{ TITLE }}",
        title,
      );

      if (metaRes?.thumbnail?.url) {
        productImage = {
          images: {
            url: metaRes.thumbnail.url,
            alt: metaRes.thumbnail?.label,
          },
        };
      }
      metaData = updateMetaObject(metaData, metaRes, currentURL, productImage);
    }
  }
  return metaData;
};

const updateCategoryMeta = async (categoryid, metaData, currentURL, pageNo) => {
  const { data, error } = await getCategoryMeta({ categoryid });
  if (error) {
    handleErrorLog({
      error,
      additional_info: { categoryid, currentURL, pageNo },
      msg: "Error while fetching category metadata",
    });
  } else {
    let shareImages = {};
    const metaRes = data?.categoryList?.[0];
    if (metaRes) {
      let catMeta = { ...metaInfo.category };
      let title = metaRes?.name;
      const addPageNumber = pageNo && Number(pageNo) > 1;
      if (addPageNumber) {
        title += ` - Page ${pageNo}`;
      }

      metaRes.meta_title = catMeta.meta_title.replace("{{ CATEGORY }}", title);
      metaRes.meta_description = catMeta.meta_description.replaceAll(
        "{{ CATEGORY }}",
        title,
      );

      if (addPageNumber) {
        metaRes.meta_description += ` - Page ${pageNo}`;
      }

      if (metaRes?.image) {
        shareImages = {
          images: {
            url: metaRes.image,
            alt: metaRes?.name,
          },
        };
      } else {
        shareImages = await getMetaImages(metaRes?.name);
      }
      metaData = updateMetaObject(metaData, metaRes, currentURL, shareImages);
    }
  }
  return metaData;
};

const updateBranchMeta = async (branch, metaData, currentURL) => {
  let metaRes = { ...metaInfo.branch };
  const location = capitalizeFirstLetter(branch?.location);

  metaRes.meta_title = metaInfo?.branch?.initial_meta_title;
  metaRes.meta_title = metaRes.meta_title.replace("{{ LOCATION }}", location);
  metaRes.meta_description = metaRes.meta_description.replace(
    "{{ LOCATION }}",
    location,
  );

  let shareImages = {};
  if (branch?.image?.url) {
    shareImages = {
      images: {
        url: branch?.image?.url,
        alt: branch?.image?.name,
      },
    };
  } else {
    shareImages = await getMetaImages(constants.contentTypes.branch);
  }

  return updateMetaObject(metaData, metaRes, currentURL, shareImages);
};

const getMakeAndModelsPageMeta = async ({
  type,
  metaData,
  currentURL,
  queryParams = {},
}) => {
  let altText = type;
  let metaRes = metaInfo.makeandmodels.archive;
  if (type === constants.contentTypes.modelselected) {
    const make = capitalizeFirstLetter(queryParams?.make || "");
    const model = capitalizeFirstLetter(queryParams?.model || "");

    altText = make + " " + model;
    metaRes = { ...metaInfo.makeandmodels.make_and_model_selected };
    metaRes.meta_title = metaRes.meta_title.replace("{{ MAKE }}", make);
    metaRes.meta_title = metaRes.meta_title.replace("{{ MODEL }}", model);
    metaRes.meta_description = metaRes.meta_description.replace(
      "{{ MAKE }}",
      make,
    );
    metaRes.meta_description = metaRes.meta_description.replace(
      "{{ MODEL }}",
      model,
    );
  } else if (type === constants.contentTypes.makeselected) {
    metaRes = { ...metaInfo.makeandmodels.make_selected };
    altText = capitalizeFirstLetter(queryParams?.make || "");
    metaRes.meta_title = metaRes.meta_title.replace("{{ MAKE }}", altText);
    metaRes.meta_description = metaRes.meta_description.replace(
      "{{ MAKE }}",
      altText,
    );
  }
  const shareImages = await getMetaImages(altText);
  return updateMetaObject(metaData, metaRes, currentURL, shareImages);
};

const fetchCMSPageMeta = async (metaType, data, baseMetaData, pageURL) => {
  const apiArr = [getMetaImages(metaType)];
  if (data?.relative_url) {
    apiArr.push(getCMSPageMeta(data?.relative_url));
  }

  const [imageRes, cmsRes] = await Promise.allSettled(apiArr);
  let dataRes = {};
  if (cmsRes?.value?.data?.blogPostByUrlKey) {
    dataRes = cmsRes?.value?.data?.cmsPage;
  }

  if (!dataRes?.meta_title || !dataRes?.meta_description) {
    if (data?.brand) {
      const brand = capitalizeFirstLetter(data?.brand);
      dataRes.meta_title = metaInfo.brand.meta_title.replace(
        "{{ BRAND }}",
        brand,
      );
      dataRes.meta_description = metaInfo.brand.meta_description.replace(
        "{{ BRAND }}",
        brand,
      );
    } else if (data?.supplier) {
      dataRes = { ...dataRes, ...metaInfo.supplier };
    }
  }

  return updateMetaObject(
    baseMetaData,
    dataRes,
    pageURL,
    imageRes?.value || {},
  );
};

const getRelatedMeta = async (metaType, data, baseMetaData, pageURL) => {
  let pageMetaData = baseMetaData;
  if (metaType === constants.contentTypes.product) {
    pageMetaData = await updateProductMeta(
      data?.productSKU,
      baseMetaData,
      pageURL,
    );
  } else if (metaType === constants.contentTypes.category) {
    pageMetaData = await updateCategoryMeta(
      data?.categoryid,
      baseMetaData,
      pageURL,
      data?.page_number,
    );
  } else if (metaType === constants.contentTypes.branches) {
    const metaImg = await getMetaImages(metaType);
    pageMetaData = updateMetaObject(
      baseMetaData,
      { ...metaInfo.branches },
      pageURL,
      metaImg,
    );
  } else if (metaType === constants.contentTypes.branch) {
    pageMetaData = updateBranchMeta(data, baseMetaData, pageURL);
  } else if (metaType === constants.contentTypes.blogs) {
    const [imgRes, blogRes] = await Promise.allSettled([
      getMetaImages(metaType),
      getBlogPageMeta(data.title),
    ]);

    let blogMeta = { meta_title: `${data.title} | GSF Car Parts` };
    if (blogRes?.value?.data?.blogPostByUrlKey) {
      blogMeta = blogRes?.value?.data?.blogPostByUrlKey;
      blogMeta.meta_title = `${blogMeta.title} | GSF Car Parts`;
    }
    pageMetaData = updateMetaObject(
      baseMetaData,
      blogMeta,
      pageURL,
      imgRes?.value || {},
    );
  } else if (metaType === constants.contentTypes.search_result) {
    const searchImg = await getMetaImages(data?.searchText);
    const sMeta = {
      ...metaInfo.search_result,
      meta_title: metaInfo.search_result.meta_title.replace(
        "{{ QUERY }}",
        data?.searchText,
      ),
    };
    pageMetaData = updateMetaObject(baseMetaData, sMeta, pageURL, searchImg);
  } else if (metaType === constants.contentTypes.CMS_PAGE) {
    pageMetaData = await fetchCMSPageMeta(
      metaType,
      data,
      baseMetaData,
      pageURL,
    );
  } else if (
    metaType === constants.contentTypes.bloglist ||
    metaType === constants.contentTypes.sitemap
  ) {
    const siteMapImg = await getMetaImages(constants.contentTypes.sitemap);
    const metaObj = {
      ...metaInfo.misc,
      meta_title: metaInfo.misc.meta_title.replace(
        "{{ HEADING }}",
        data.heading,
      ),
    };
    pageMetaData = updateMetaObject(baseMetaData, metaObj, pageURL, siteMapImg);
  } else if (
    metaType === constants.contentTypes.makeselected ||
    metaType === constants.contentTypes.modelselected ||
    metaType === constants.contentTypes.makeandmodels
  ) {
    pageMetaData = await getMakeAndModelsPageMeta({
      type: metaType,
      queryParams: data,
      currentURL: pageURL,
      metaData: baseMetaData,
    });
  }

  return pageMetaData;
};

export const getCurrentURL = (path, withSearchQuery = false) => {
  if (!path) return "";
  try {
    const urlSchema = new URL(path);
    let currentURL = `${urlSchema.origin}${urlSchema.pathname}`;
    if (!path.includes(keys.general.websiteUrl)) {
      const domain = new URL(keys.general.websiteUrl);
      currentURL = `${domain.origin}${urlSchema.pathname}`;
    }

    if (withSearchQuery) {
      currentURL += urlSchema?.search;
    }
    return currentURL;
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { path },
      msg: "Error while updating current path",
    });
    return "";
  }
};

export const staticMetaInfo = ({ pageURL, canonicalPath, query = false }) => {
  const enableBot = keys.general.enableBot === "1";
  let metaData = {
    ...staticMeta,
    robots: {
      index: enableBot,
      follow: enableBot,
      googleBot: {
        index: enableBot,
        follow: enableBot,
      },
    },
  };

  let currentURL = "";
  if (canonicalPath) {
    currentURL = getCurrentURL(canonicalPath, query);
  } else if (pageURL) {
    currentURL = pageURL;
  }

  if (currentURL) {
    metaData.alternates = { canonical: currentURL };
  }
  return metaData;
};

export const getPageMetaInfo = async ({
  type,
  canonicalPath,
  pageData = {},
}) => {
  let pageString = "";
  let currentURL = getCurrentURL(canonicalPath, true);
  if (pageData?.page_number) {
    currentURL = `${currentURL}?page=${pageData.page_number}`;
    pageString = " - Page " + pageData.page_number;
  }

  const enableBot = keys.general.enableBot === "1";
  let metaData = {
    ...staticMeta,
    alternates: { canonical: currentURL },
    robots: {
      index: enableBot,
      follow: enableBot,
      googleBot: {
        index: enableBot,
        follow: enableBot,
      },
    },
  };

  metaData = await getRelatedMeta(type, pageData, metaData, currentURL);
  if (pageString && !metaData?.title?.includes(pageString)) {
    metaData.title += pageString;
    if (metaData?.openGraph) {
      metaData.openGraph.title += pageString;
    }
  }
  return metaData;
};

export const getProductSchema = (product, canonicalPath) => {
  const branch = product?.branch || {};
  const brand = product.brands?.[0] || {};
  const review = brand?.review || null;
  const homeDeliveryStock = brand?.quantity > 0;
  const productURL = getCurrentURL(canonicalPath);
  const imageArr = product.media_gallery.map((m) => m?.url).filter(Boolean);
  let availability = homeDeliveryStock;
  if (
    !availability &&
    branch?.branch_id &&
    !brand?.branch_stock &&
    !brand?.hub_stock
  ) {
    availability = false;
  }

  let schemaObj = {
    "@context": "https://schema.org/",
    "@type": "Product",
    sku: brand?.sku,
    url: productURL,
    description: product.description.html,
    image: !imageArr.length ? product.image : imageArr,
    name: brand?.product_title_with_brand || product.product_title,
    brand: {
      "@type": "Brand",
      name: brand?.title?.toUpperCase(),
    },
    offers: {
      "@type": "Offer",
      url: productURL,
      priceCurrency: brand?.currency,
      price: brand?.final_price?.substring(1),
      itemCondition: "https://schema.org/NewCondition",
      availability: `https://schema.org/${
        availability ? "InStock" : "OutOfStock"
      }`,
      priceSpecification: {
        priceCurrency: brand?.currency,
        price: brand?.final_price?.substring(1),
        valueAddedTaxIncluded: "https://schema.org/True",
      },
      seller: {
        "@type": "Organization",
        name: "GSF Car Parts",
      },
    },
  };

  if (review) {
    schemaObj["aggregateRating"] = {
      "@type": "AggregateRating",
      bestRating: 5,
      reviewCount: review?.num_ratings,
      ratingValue: parseFloat(parseFloat(review?.average_rating).toFixed(1)),
    };
  }
  return schemaObj;
};

export const getBreadcrumbSchema = (breadcrumbList) => {
  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [],
  };

  const totalLength = breadcrumbList.length;
  breadcrumbList.forEach((item, i) => {
    let listItem = {
      "@type": "ListItem",
      position: i + 1,
      name: item.title,
    };
    if (totalLength !== listItem.position) {
      listItem.item = item.url;
    }
    schema.itemListElement.push(listItem);
  });

  return schema;
};

export const getOrgnizationSchema = async ({
  homeData = null,
  branch = null,
}) => {
  let orgSchema = {
    "@context": "http://schema.org",
    "@type": "Organization",
    url: keys.general.websiteUrl,
    vatID: "135606620",
    name: "GSF Car Parts",
    logo: "",
    image: "",
    telephone: "+44 121 626 7971",
    email: "enquiries@gsfgroup.com",
    description: "GSF Car Parts is a leading automotive parts business.",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+44 121 626 7971",
      email: "enquiries@gsfgroup.com",
    },
    sameAs: ["https://www.facebook.com/gsfcarparts"],
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 105 Midpoint Park Kingsbury Road",
      addressLocality: "Sutton Coldfield",
      addressCountry: "GB",
      addressRegion: "Minworth",
      postalCode: "B76 1AF",
    },
  };

  const { data: orgDdata } = await getOrgnizationInfo();
  if (orgDdata?.items?.length > 0) {
    const fields = orgDdata.items[0]?.fields;
    if (fields?.name) {
      orgSchema.name = fields.name;
      orgSchema.vatID = fields?.vatId || orgSchema.vatID;
      orgSchema.email = fields?.email || orgSchema.email;
      orgSchema.sameAs = fields?.sameAs || orgSchema.sameAs;
      orgSchema.description = fields?.description || orgSchema.description;
      orgSchema.contactPoint.email =
        fields?.email || orgSchema.contactPoint.email;
      orgSchema.address.postalCode =
        fields?.postalCode || orgSchema.address.postalCode;
      orgSchema.address.postalCode =
        fields?.addressCountry || orgSchema.address.addressCountry;
      orgSchema.address.streetAddress =
        fields?.streetAddress || orgSchema.address.streetAddress;
      orgSchema.address.addressRegion =
        fields?.addressRegion || orgSchema.address.addressRegion;
      orgSchema.address.addressLocality =
        fields?.addressLocality || orgSchema.address.addressLocality;
      if (fields?.telephone) {
        const formattedNumber = formatToInternationalUKPhoneNumber(
          fields?.telephone,
        );
        orgSchema.telephone = formattedNumber;
        orgSchema.contactPoint.telephone = formattedNumber;
      }

      const { imageUrl: logoURL } = getContentfulImgData(
        fields?.logo?.fields?.image,
      );
      const { imageUrl } = getContentfulImgData(fields?.image);
      if (logoURL) orgSchema.logo = logoURL;
      if (imageUrl) orgSchema.image = imageUrl;
    }
  }

  if (branch?.branch_id) {
    if (branch?.hyperlink) {
      orgSchema.url += `/branches/${branch.hyperlink}`;
    }

    if (branch?.telephone) {
      const formattedNumber = formatToInternationalUKPhoneNumber(
        branch.telephone,
      );
      orgSchema.telephone = formattedNumber;
      orgSchema.contactPoint.telephone = formattedNumber;
    }

    if (branch?.image?.length > 0) {
      const imageObj = branch.image[0];
      orgSchema.image = imageObj?.url || orgSchema.image;
    }

    orgSchema.name += ` - ${branch?.name}`;
    orgSchema.email = branch?.email || orgSchema.email;
    orgSchema.description = branch?.description || orgSchema.description;
    orgSchema.contactPoint.email =
      branch?.email || orgSchema.contactPoint.email;
    orgSchema.address.streetAddress =
      branch?.address1 || orgSchema.address.streetAddress;
    orgSchema.address.addressLocality =
      branch?.address2 || orgSchema.address.addressLocality;
    orgSchema.address.addressRegion =
      branch?.address3 || orgSchema.address.addressRegion;
    orgSchema.address.postalCode =
      branch?.postcode || orgSchema.address.postalCode;
  } else if (!orgSchema.image && homeData?.seo?.fields?.shareImage) {
    const { imageUrl } = getContentfulImgData(homeData.seo.fields.shareImage);
    if (imageUrl) {
      orgSchema.image = imageUrl;
    }
  }
  return orgSchema;
};

export const getWebSiteSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: keys.general.websiteUrl,
    potentialAction: {
      "@type": "SearchAction",
      target: `${
        keys.general.websiteUrl + routes.searchResults
      }?q={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };
};
