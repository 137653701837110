import { toast } from "react-toastify";
import { constants } from "./constants";
import { pageTypePath } from "./routes";
import { handleErrorLog } from "./error_logs";
import { isEmptyObject } from "./browserMethods";
import CustomToaster, {
  ProductToast,
} from "@/components/common/shared/CustomToaster";
import {
  resetCartAddressThunk,
  setBillingAddressThunk,
  setShippingAddressThunk,
} from "../store/reducers/cart";
import {
  fireAddToCartEvent,
  firePlaceOrderEvent,
  fireSearchResponseReceivedEvent,
} from "./livesearch";
import Cookies from "js-cookie";

/**
 * @param {string} message
 * @param {import("react-toastify/dist/types").ToastOptions} options
 */
export const handleToastError = (message, options = {}) => {
  toast.error(
    (toastProps) => <CustomToaster message={message} {...toastProps} />,
    {
      toastId: message,
      icon: false,
      closeButton: false,
      ...options,
    },
  );
};

/**
 * @param {string} message
 * @param {import("react-toastify/dist/types").ToastOptions} options
 */
export const handleToastSuccess = (message, options = {}) => {
  toast.success(
    (toastProps) => <CustomToaster message={message} {...toastProps} />,
    {
      icon: false,
      closeButton: false,
      ...options,
      autoClose: 2000,
      hideProgressBar: true,
    },
  );
};

/**
 * @param {string} message
 * @param {import("react-toastify/dist/types").ToastOptions} options
 */
export const handleToastInfo = (message, options = {}) => {
  toast.info(
    (toastProps) => <CustomToaster message={message} {...toastProps} />,
    {
      icon: false,
      closeButton: false,
      ...options,
    },
  );
};

export const handleToastProduct = ({ name, image, showCTA }, options = {}) => {
  toast.success(
    (toastProps) => (
      <ProductToast name={name} image={image} showCTA={false} {...toastProps} />
    ),
    {
      icon: false,
      closeButton: false,
      autoClose: 2000,
      hideProgressBar: true,
      ...options,
    },
  );
};

export const handleGraphqlResponse = (res, getUserErrors = () => { }) => {
  const { data, errors } = res;
  if (errors) {
    throw new Error(errors[0].message);
  }
  const userErrors = getUserErrors?.(data);
  if (userErrors?.length) {
    throw new Error(userErrors[0].message);
  }
  return data;
};

export const handleThunkResponse = (res) => {
  const { payload, error } = res;
  if (error) {
    throw new Error(error.message);
  }
  return payload;
};

export const getCustomerName = (data) => {
  const { firstname, middlename, lastname, suffix } = data || {};
  return [firstname, middlename, lastname, suffix].filter(Boolean).join(" ");
};

export const getAddressFromId = (addressId, addresses = []) => {
  return addresses.find(({ id }) => +id === +addressId);
};

export const getAddressDetails = (address, t) => {
  if (!address) return;
  if (!Object.keys(address).length) return;
  const addressClone = structuredClone(address);

  let {
    id,
    customer_address_id,
    street = [],
    city,
    region = {},
    postcode,
    country_code,
    company,
    country,
  } = addressClone || {};

  if (region?.code) {
    region.region_code = region.code;
    region.region = region.label;
  }

  if (country?.code) {
    country_code = country.code;
  }

  if (customer_address_id) {
    id = customer_address_id;
  }

  const addressObj = {
    ...addressClone,
    id,
    fullname: getCustomerName(addressClone),
    fullstreet: street.join(", "),
    country: t?.(`countries:${country_code}`) || country_code,
  };
  const addressArr = [
    getCustomerName(addressClone),
    company,
    ...street,
    [city, region?.region, postcode].filter(Boolean).join(", "),
    t?.(`countries:${country_code}`) || country_code,
  ].filter(Boolean);

  return {
    ...addressObj,
    addressArr,
  };
};

export const formatProductPrice = (amount) => {
  let priceTag = { amount: "0.00", currencySymbol: constants.currency.GBP };
  if (amount) {
    let symbol = "";
    if (amount?.symbol) {
      symbol = amount?.symbol;
    } else if (amount?.currency) {
      symbol = constants.currency?.[amount.currency];
    }

    priceTag.currencySymbol = symbol || priceTag.currencySymbol;

    try {
      let price = amount?.value;
      price = parseFloat(price);
      if (isNaN(price)) price = 0;
      priceTag.amount = price.toFixed(2);
    } catch (error) {
      handleErrorLog({
        error,
        additional_info: { amount },
        msg: "Error while checking Formattng amount",
      });
      priceTag.amount = "0.00";
    }
  }
  return `${priceTag.currencySymbol}${priceTag.amount}`;
};

export const getProductsMap = (products = []) => {
  const productsMap = {};
  const freeGiftsMap = {};

  // seperate cart products from free ones
  products.forEach((details) => {
    const { product, uid } = details;
    const { sku } = product;

    productsMap[uid] = {
      ...details,
      product: { ...product, sku: sku.toUpperCase() },
    };
  });

  // update logic to update free gift and products seprrately

  return { cartProducts: productsMap, freeGifts: freeGiftsMap };
};

export const getProductsSKUMap = (products = []) => {
  const productsMap = {};

  const filteredProducts = products.filter((el) => el?.uid)

  filteredProducts.forEach((details) => {
    const { product , quantity = 0 } = details;
    const { sku } = product;  
    const skuUpper = sku.toUpperCase();

    if (productsMap[skuUpper]) {
      productsMap[skuUpper].quantity += quantity; 
    } else {
      productsMap[skuUpper] = {
        ...details,
        product: { ...product, sku: skuUpper },
      };
    }
  });

  return productsMap;
};

export const formatGeocodeError = (code) => {
  let message = "";
  switch (code) {
    case "ZERO_RESULTS": {
      message =
        "Unable to get location/postal code details. Please enter valid location/postal code.";
      break;
    }
    case "INVALID_REQUEST": {
      message = "Please enter a location/postal code";
      break;
    }
    default: {
      message = "Unable to get branches";
      break;
    }
  }
  return message;
};

export const mutateProductInfo = (
  data,
  isCart = false,
  bestSellerImg = "",
  recommendedImg = "",
) => {
  let info = isCart ? { ...data?.product } : { ...data };
  const brands = info?.extraVariable?.brand_info || [];
  const firstBrand = brands?.length > 0 ? brands[0] : {};
  info.first_brand = firstBrand;
  info.original_name = info?.name;
  info.bestseller_img = bestSellerImg;
  info.recommended_img = recommendedImg;
  if (firstBrand?.title) {
    info.name = `${firstBrand.title?.toUpperCase()} - ${info.name}`;
  }

  const minPrice = info?.price_range?.minimum_price || {};
  const amountOff = minPrice?.discount?.amount_off || 0;
  const percentOff = minPrice?.discount?.percent_off || 0;

  info.showDiscountedPrice = amountOff >= 5;
  info.final_price = formatProductPrice(minPrice?.final_price);
  info.regular_price = formatProductPrice(minPrice?.regular_price);
  info.percent_off = percentOff > 9 ? `${percentOff}` : `0${percentOff}`;
  info.amountOff = formatProductPrice({
    ...minPrice?.final_price,
    value: amountOff,
  });

  info.showStrikePrice =
    minPrice?.final_price?.value < minPrice?.regular_price?.value ||
    !!info?.special_price;

  return isCart ? { ...data, product: info } : info;
};

export const mutateFreeGiftInfo = (freeCartItem, isOrderDetails = false) => {
  const label = "Assured Free Gift";

  let obj = {
    name: freeCartItem?.product?.name,
    image: freeCartItem?.product?.thumbnail?.url,
    sku: freeCartItem?.product?.sku,
  };

  if (isOrderDetails) {
    obj = {
      name: `${freeCartItem?.product?.extraVariable?.brand_info?.[0]?.title}- ${freeCartItem?.product_name || freeCartItem?.product?.name
        }`,
      image: freeCartItem?.product?.image?.url,
      sku: freeCartItem?.product_sku || freeCartItem?.product?.sku,
    };
  }

  if (!freeCartItem || !label) {
    return null;
  }

  return {
    label,
    products: [obj],
  };
};

export const beginCheckoutPayloadGA4 = (data) => {
  const { cartPrice, cartProducts, appliedCoupons } = data;

  const totalValue = cartPrice?.applied_taxes.reduce((accumulator, current) => {
    return accumulator + current.amount.value;
  }, 0);

  const products = Object.values(cartProducts).map((el) => {
    return {
      ...el.product,
      first_brand: el?.product?.extraVariable?.brand_info?.[0],
      quantity: el.quantity,
    };
  });

  let payload = {
    ecommerce: {
      value: cartPrice?.subtotal_with_discount_excluding_tax?.value,
      currency: cartPrice?.subtotal_with_discount_excluding_tax?.currency,
      tax: totalValue,
      items: formatProductGA4(products, appliedCoupons),
    },
  };

  return payload;
};

export const createPurchasePayloadGA4 = (data) => {
  const {
    cartPrice,
    cartEmail,
    cartProducts,
    appliedCoupons,
    selectedShippingMethod,
    user,
    orderType = "Home Delivery",
  } = data;

  const totalValue = cartPrice?.applied_taxes.reduce((accumulator, current) => {
    return accumulator + current.amount.value;
  }, 0);

  const products = Object.values(cartProducts).map((el) => {
    return {
      ...el.product,
      prices: mutateProductInfo(el, true)?.prices,
      first_brand: el?.product?.extraVariable?.brand_info?.[0],
      quantity: el.quantity,
    };
  });

  let payload = {
    email: cartEmail,
    ecommerce: {
      value: cartPrice?.subtotal_with_discount_excluding_tax?.value,
      currency: cartPrice?.subtotal_with_discount_excluding_tax?.currency,
      transaction_type: orderType,
      tax: totalValue,
      shipping: selectedShippingMethod?.amount?.value || 0.0,
      items: formatProductGA4(products, appliedCoupons, true).map((el) => ({
        ...el,
        affiliation: orderType,
      })),
    },
  };

  //check customerr logged in
  if (user?.id) {
    payload.user_id = String(user?.id);
  }

  return payload;
};

export const createPaymentInfoPayloadGA4 = (data) => {
  const {
    cartPrice,
    currency,
    cartProducts,
    appliedCoupons,
    selectedPaymentType,
  } = data;

  const products = Object.values(cartProducts).map((el) => {
    return {
      ...el.product,
      prices: mutateProductInfo(el, true)?.prices,
      first_brand: el?.product?.extraVariable?.brand_info?.[0],
      quantity: el.quantity,
    };
  });
  let payload = {
    value: cartPrice,
    currency: currency,
    payment_type: selectedPaymentType,
    items: formatProductGA4(products, appliedCoupons, true),
  };
  return payload;
};

export const createShippingInfoPayloadGA4 = (data) => {
  const {
    currency,
    cartProducts,
    appliedCoupons,
    selectedShippingMethod,
    shippingAmount,
  } = data;

  const products = Object.values(cartProducts).map((el) => {
    return {
      ...el.product,
      prices: mutateProductInfo(el, true)?.prices,
      first_brand: el?.product?.extraVariable?.brand_info?.[0],
      quantity: el.quantity,
    };
  });
  let payload = {
    currency: currency,
    shipping_tier: selectedShippingMethod,
    items: formatProductGA4(products, appliedCoupons, true),
  };
  payload.value = (
    payload?.items?.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0) + shippingAmount.value
  ).toFixed(2);
  return payload;
};

export const getGeocodeLatLng = async (address = "") => {
  const { results } = await new google.maps.Geocoder().geocode({
    address: address + " ,UK",
  });
  const [res] = results;
  const latLng = res.geometry.location.toJSON();

  return latLng;
};

export const updateCartShippingAddress = async ({
  currentAddr,
  type,
  dispatch,
  customerDetails,
  shipAddr,
}) => {
  const {
    default_shipping: current_default_shipping = false,
    id: currentAddrId,
  } = currentAddr;

  const currShipAddrId = getAddressDetails(shipAddr)?.id;
  if (type === "add") {
    //Case: When adding a new address;
    if (current_default_shipping) {
      const res = await dispatch(
        setShippingAddressThunk({ addressId: currentAddrId }),
      );
      handleThunkResponse(res);
    }
  } else {
    //When editing the existing address;
    const default_shipping_before = !!getAddressFromId(
      currentAddrId,
      customerDetails?.addresses,
    )?.default_shipping;
    //If there has been a change in the default shipping;
    if (default_shipping_before !== current_default_shipping) {
      if (current_default_shipping) {
        //Case: This address is being set as default shipping;
        //So we need to associate it with the cart;
        const res = await dispatch(
          setShippingAddressThunk({ addressId: currentAddrId }),
        );
        handleThunkResponse(res);
      } else if (currShipAddrId === currentAddrId) {
        //Case: It was set as default_shipping, now the user is removing it from being default_shiping;
        // And this address is also set as current shipping address;
        //So, we need to deassociate it from the cart;
        const res = await dispatch(resetCartAddressThunk("shipping"));
        handleThunkResponse(res);
      }
    } else if (currShipAddrId === currentAddrId) {
      //Current address that is being updated is also set as shipping addres;
      //No Change made in the default shipping addres checkbox;
      //But some other field could have been changed.
      //So need to updated the shipping address of cart with latest changes;
      const res = await dispatch(
        setShippingAddressThunk({ addressId: currentAddrId }),
      );
      handleThunkResponse(res);
    }
  }
};

export const updateCartBillingAddress = async ({
  currentAddr,
  type,
  dispatch,
  customerDetails,
  billAddr,
}) => {
  const {
    default_billing: current_default_billing = false,
    id: currentAddrId,
  } = currentAddr;

  const currBillAddrId = getAddressDetails(billAddr)?.id;
  if (type === "add") {
    //Case: When adding a new address;
    if (current_default_billing) {
      const res = await dispatch(
        setBillingAddressThunk({ addressId: currentAddrId }),
      );
      handleThunkResponse(res);
    }
  } else {
    //When editing the existing address;
    const default_billing_before = !!getAddressFromId(
      currentAddrId,
      customerDetails?.addresses,
    )?.default_billing;
    //If there has been an change in the default billing;
    if (default_billing_before !== current_default_billing) {
      if (current_default_billing) {
        //Case: This address is being set as default billing;
        //So we need to associate it with the cart;
        const res = await dispatch(
          setBillingAddressThunk({ addressId: currentAddrId }),
        );
        handleThunkResponse(res);
      } else if (currBillAddrId === currentAddrId) {
        //Case: It was set as default_billing, now the user is removing it from being default billing;
        // And this address is also set as current billing address;
        //So, we need to deassociate it from the cart;
        const res = await dispatch(resetCartAddressThunk("billing"));
        handleThunkResponse(res);
      }
    } else if (currBillAddrId === currentAddrId) {
      //Current address is also set as cart billing address;
      //No Change made in the default billing address checkbox;
      //But some other field could have been changed.
      //So need to updated the billing address of cart with latest changes;
      const res = await dispatch(
        setBillingAddressThunk({ addressId: currentAddrId }),
      );
      handleThunkResponse(res);
    }
  }
};

export const updateCartShippingAddressForDeletion = async ({
  currRemovedAddrId,
  updatedUserAddresses,
  shipAddr,
  dispatch,
}) => {
  const currShipAddrId = getAddressDetails(shipAddr)?.id;
  if (!updatedUserAddresses.addresses?.length) {
    //Case: Last address is removed. and also there is a shipping address set for cart;
    if (currShipAddrId) {
      const res = await dispatch(resetCartAddressThunk("shipping"));
      handleThunkResponse(res);
    }
  } else if (currRemovedAddrId === currShipAddrId) {
    //Case: There are still other address and removed address is also the current shipping address:
    const defaultShipAddr = updatedUserAddresses?.addresses?.find?.(
      (adr) => adr.default_shipping,
    );
    // - if there is no default shipping address for the user from the remaining addresses --> reset
    // - else set the default shipping address as the cart shipping address;
    if (defaultShipAddr) {
      const res = await dispatch(
        setShippingAddressThunk({ addressId: defaultShipAddr?.id }),
      );
      handleThunkResponse(res);
    } else {
      const res = await dispatch(resetCartAddressThunk("shipping"));
      handleThunkResponse(res);
    }
  }
};

export const updateCartBillingAddressForDeletion = async ({
  currRemovedAddrId,
  updatedUserAddresses,
  billAddr,
  dispatch,
}) => {
  const currBillAddrId = getAddressDetails(billAddr)?.id;
  if (!updatedUserAddresses.addresses?.length) {
    //Case: Last address is removed. and also there is a billing address set for cart;
    if (currBillAddrId) {
      const res = await dispatch(resetCartAddressThunk("billing"));
      handleThunkResponse(res);
    }
  } else if (currRemovedAddrId === currBillAddrId) {
    //Case: There are still other address and removed address is also the current billing address:
    const defaultBillAddr = updatedUserAddresses?.addresses?.find?.(
      (adr) => adr.default_billing,
    );
    // - if there is no default billing address for the user from the remaining addresses --> reset
    // - else set the default billing address as the cart billing address;
    if (defaultBillAddr) {
      const res = await dispatch(
        setBillingAddressThunk({ addressId: defaultBillAddr?.id }),
      );
      handleThunkResponse(res);
    } else {
      const res = await dispatch(resetCartAddressThunk("billing"));
      handleThunkResponse(res);
    }
  }
};

export const convertGarageDataToCookieFormat = (vehicleDetails) => {
  const res = {
    session: {
      sessionId: vehicleDetails?.session_id,
      statusCode: "Vehicle_Found",
    },
    vehicle: vehicleDetails?.data,
  };
  return res;
};

export const showVehicleInfo = (details = null) => {
  if (isEmptyObject(details)) return "";
  if (isEmptyObject(details?.vehicle)) return "";

  let detailArr = [];
  if (details?.vehicle?.make) detailArr.push(details?.vehicle?.make);
  if (details?.vehicle?.model) detailArr.push(details?.vehicle?.model);
  if (details?.vehicle?.engineSize)
    detailArr.push(details?.vehicle?.engineSize);
  if (details?.vehicle?.fuel) detailArr.push(details?.vehicle?.fuel);
  if (details?.vehicle?.dateOfManufacture) {
    let dateArr = [];
    if (details?.vehicle?.dateOfManufacture) {
      dateArr = details?.vehicle?.dateOfManufacture.split("-");
    }
    detailArr.push(dateArr[0] || "");
  }
  if (details?.vehicle?.modelVersion) {
    detailArr.push(
      `(${(details?.vehicle?.modelVersion.match(/\(([^)]+)\)/) || [])[1]})`,
    );
  }

  return detailArr.join(" / ");
};

export const isVRM = (selectedVehicle) => {
  return !selectedVehicle.includes("_");
};

export const MVLKeyToQueryString = (key) => {
  const paramNames = [
    "manufacturerId",
    "modelId",
    "type",
    "modelVersionId",
    "engineSizeId",
    "bodyStyleId",
    "powerId",
  ];

  const values = key.split("_");
  const params = paramNames.reduce((acc, paramName, index) => {
    if (values[index] !== undefined) {
      acc[paramName] = values[index];
    }
    return acc;
  }, {});

  const queryString = Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join("&");

  return queryString;
};

export const MVLQueryStringToKey = (queryString) => {
  const paramNames = [
    "manufacturerId",
    "modelId",
    "type",
    "modelVersionId",
    "engineSizeId",
    "bodyStyleId",
    "powerId",
  ];
  const params = new URLSearchParams(queryString);
  const values = paramNames.map((paramName) => params.get(paramName) || "");
  const key = values.filter((value) => value !== "").join("_");
  return key;
};

export const MVLPayloadToKey = (payload) => {
  const parts = [];
  if (payload?.make) parts.push(payload?.make);
  if (payload?.model) parts.push(payload?.model);
  if (payload?.type) parts.push(payload?.type);
  if (payload?.year) parts.push(payload?.year);
  if (payload?.engine) parts.push(payload?.engine);
  if (payload?.body) parts.push(payload?.body);
  if (payload?.kw_hp) parts.push(payload?.kw_hp);
  return parts.join("_");
};

export const formatProductGA4 = (
  productArr = [],
  coupons = [],
  isCart = false,
) => {
  const mutatedItems = productArr.map((product, i) => {
    let price = product?.price_range?.minimum_price?.final_price?.value;
    let discount = product?.price_range?.minimum_price?.discount?.amount_off;

    // if on cart update discounts and price
    if (isCart) {
      const regularPrice = product?.price_range?.minimum_price?.regular_price;
      const rowTotalIncTax = product?.prices?.row_total_including_tax?.value;
      const oldPrice = (regularPrice?.value ?? 0) * (product?.quantity ?? 0);
      const amountOff = (oldPrice - rowTotalIncTax) / product?.quantity || 1;

      const singleProductPrice = product?.prices?.price_including_tax?.value;

      discount = +amountOff.toFixed(2);
      price = singleProductPrice;
    }

    const specialCouponApplied =
      !product?.specialCoupon_applied && discount > 0;
    return {
      price,
      ...(coupons?.length &&
        specialCouponApplied && {
        coupon: coupons[0].code,
      }),
      item_id: product?.sku,
      item_name: product?.original_name || product?.name,
      quantity: product?.quantity,
      item_brand: product?.first_brand?.title,
      index: i,
      discount,
      ...formatProductCategoriesGA4(product?.extraVariable?.breadcrumbs),
    };
  });

  return mutatedItems;
};

export const formatRemovedProductGA4 = (
  productArr = [],
  coupons = [],
  isCart = false,
) => {
  const mutatedItems = productArr.map((product, i) => {
    let price = product?.price_range?.minimum_price?.final_price?.value;
    let discount = product?.price_range?.minimum_price?.discount?.amount_off;

    // if on cart update discounts and price
    if (isCart) {
      const regularPrice = product?.price_range?.minimum_price?.regular_price;
      const rowTotalIncTax = product?.prices?.price_including_tax?.value;
      const oldPrice = regularPrice?.value ?? 0;

      const singleProductPrice = product?.prices?.price_including_tax?.value;
      const amountOff = oldPrice - singleProductPrice;

      discount = +amountOff.toFixed(2);
      price = singleProductPrice;
    }

    const specialCouponApplied =
      !product?.specialCoupon_applied && discount > 0;
    return {
      price,
      ...(coupons?.length &&
        specialCouponApplied && {
        coupon: coupons[0].code,
      }),
      item_id: product?.sku,
      item_name: product?.original_name || product?.name,
      quantity: product?.quantity,
      item_brand: product?.first_brand?.title,
      index: i,
      discount,
      ...formatProductCategoriesGA4(product?.extraVariable?.breadcrumbs),
    };
  });

  return mutatedItems;
};

export const formatProductCategoriesGA4 = (breadcrumbs) => {
  let categories = {};
  breadcrumbs &&
    breadcrumbs.forEach((breadcrumb, index) => {
      const key = index === 0 ? "item_category" : `item_category${index + 1}`;
      categories[key] = breadcrumb.label;
    });
  return categories;
};

export const updateGA4PayloadToSelectItem = (
  ga4_payload,
  breadcrumbs,
  item_list,
) => {
  return {
    item_list_id: item_list
      ?.toLowerCase()
      ?.replace(/\s+/g, "_")
      ?.replace(/[^\w_]/g, "")
      ?.replace(/_+/g, "_"),
    items: [
      {
        item_id: ga4_payload?.items[0]?.item_id,
        item_name: ga4_payload?.items[0]?.item_name,
        discount: ga4_payload?.items[0]?.discount,
        item_brand: ga4_payload?.items[0]?.item_brand,
        price: ga4_payload?.items[0]?.price,
        quantity: ga4_payload?.items[0]?.quantity,
        index: ga4_payload?.items[0]?.index,
        ...(ga4_payload?.items[0]?.coupon && {
          coupon: ga4_payload?.items[0]?.coupon,
        }),
        ...formatProductCategoriesGA4(breadcrumbs),
        item_list_name: item_list,
      },
    ],
  };
};

export const getPageType = () => {
  const path = location?.pathname;

  const includesPath = (pathsArray) => pathsArray.some((p) => path.includes(p));

  switch (true) {
    case path === pageTypePath.home:
      return "Home";

    case includesPath(pageTypePath.customer):
      return "Customer";

    case includesPath(pageTypePath.cms):
      return "CMS";

    case path.includes(pageTypePath.newsletter):
      return "Newsletter";

    case path.includes(pageTypePath.checkout):
      switch (true) {
        case path.includes(pageTypePath.cart):
          return "Cart";
        default:
          return "Checkout";
      }

    case path.includes(pageTypePath.branch):
      return "Branch";

    case path.includes(pageTypePath.blog):
      return "Blog";

    case path.includes(pageTypePath.search):
      return "Search";

    case path.includes(pageTypePath.makeAndModels):
      return "Make and Models";

    case path.includes(pageTypePath.sitemap):
      return "Sitemap";

    case path.includes(pageTypePath.product):
      return "Product";

    case pageTypePath.category.includes(path):
      return "Category";

    case includesPath(pageTypePath.category):
      return "Sub Category";

    default:
      return path;
  }
};

export function transformSearchResponse(
  newApiResponse,
  executionTime,
  currentPage,
  pageSize,
  inputSearchContext,
) {
  const transformedResponse = {
    products: {
      request_id: newApiResponse?.productSearch?.request_id,
      executionTime: executionTime,
      currentPage: currentPage,
      pageSize: pageSize,
      search_trems: newApiResponse?.productSearch?.search_trems,
      category_count:
        newApiResponse?.productSearch?.page_info?.cate_total_count,
      categories:
        newApiResponse?.productSearch?.categories?.map((category) => ({
          id: category?.id,
          name: category?.name,
          link: category?.link,
          image: category?.image,
        })) || [],
      total_count: newApiResponse?.productSearch?.total_count,
      items:
        newApiResponse?.productSearch?.items?.map((item) => ({
          id: item?.productView?.externalId,
          sku: item?.productView?.sku,
          name: item?.productView?.name,
          url_key: item?.productView?.urlKey,
          best_seller: item?.productView?.best_seller,
          special_price:
            item?.productView?.price?.final?.amount?.value <
              item?.productView?.price?.regular?.amount?.value
              ? item?.productView?.price?.final?.amount?.value
              : null,
          sold_in_pairs: item?.productView?.sold_in_pairs || 0,
          is_recommended: item?.productView?.is_recommended,
          specialCoupon_applied:
            newApiResponse?.productSearch?.special_coupon_applied,
          thumbnail: {
            url: item?.productView?.images?.length
              ? item?.productView?.images[0]?.url
              : "",
            label: item?.productView?.images?.length
              ? item?.productView?.images[0]?.label
              : "",
          },
          price_range: {
            minimum_price: {
              regular_price: {
                value: item?.productView?.price?.regular?.amount?.value,
                currency: item?.productView?.price?.regular?.amount?.currency,
              },
              final_price: {
                value: item?.productView?.price?.final?.amount?.value,
                currency: item?.productView?.price?.final?.amount?.currency,
              },
              discount: {
                amount_off:
                  item?.productView?.price?.regular?.amount?.value -
                  item?.productView?.price?.final?.amount?.value,
                percent_off:
                  item?.productView?.price?.regular?.amount?.value !== 0
                    ? ((item?.productView?.price?.regular?.amount?.value -
                      item?.productView?.price?.final?.amount?.value) /
                      item?.productView?.price?.regular?.amount?.value) *
                    100
                    : 0,
              },
            },
          },
          // uid: item.productView.sku,
          extraVariable: {
            qty: item?.productView?.inStock ? 1 : 0,
            art_nr: item?.productView?.art_nr,
            breadcrumbs: item?.productView?.breadcrumbs || [],
            stop_start_compatible: item?.productView?.stop_start_compatible,
            warranty_guaranteed_text:
              item?.productView?.warranty_guaranteed_text,
            brand_info: item?.productView?.brand_info
              ? [
                {
                  id: item?.productView?.brand_info?.id,
                  title: item?.productView?.brand_info?.title,
                  image: item?.productView?.brand_info?.image,
                  brand_tier: item?.productView?.brand_info?.brand_tier,
                },
              ]
              : [],
            // fitmentInfo: item.productView.attributes || []
          },
        })) || [],
      suggestions: newApiResponse?.productSearch?.suggestions,
      related_terms: newApiResponse?.productSearch?.related_terms,
      inputSearchContext: inputSearchContext,
    },
  };
  return transformedResponse;
}

export const returnProductCtx = (productEntry) => {
  return {
    productId: productEntry?.product?.id,
    name: productEntry?.product?.name,
    sku: productEntry?.product?.sku,
    topLevelSku: productEntry?.product?.sku,
    categories:
      productEntry?.product?.extraVariable?.breadcrumbs?.map((cat) => cat.id) ||
      [],
    productType: "simple",
    mainImageUrl: productEntry?.product?.thumbnail?.url,
    canonicalUrl: location.href,
    pricing: {
      regularPrice:
        productEntry?.product?.price_range?.minimum_price?.regular_price?.value,
      minimalPrice:
        productEntry?.product?.price_range?.minimum_price?.final_price?.value,
      maximalPrice:
        productEntry?.product?.price_range?.minimum_price?.regular_price
          ?.value,
      currencyCode:
        productEntry?.product?.price_range?.minimum_price?.final_price
          ?.currency,
    },
  };
};

export const returnShoppingCartCtx=(productData,_cartPrice,total_Count)=>{
  return {
    giftMessageSelected: false,
    giftWrappingSelected: false,
    id: localStorage.getItem(constants.localStorage.cartId),
    items: Object.keys(productData).map((key) => {
      const product = productData[key];
      return {
        canApplyMsrp: false,
        configurableOptions: [],
        formattedPrice: product?.product?.price_range?.minimum_price?.final_price?.value,
        id: String(product?.product?.id),
        prices: {
          price: {
            value:
              product?.product?.price_range?.minimum_price?.final_price?.value,
            currency:
              product?.product?.price_range?.minimum_price?.final_price
                ?.currency,
          },
        },
        product: returnProductCtx(product),
        quantity: product?.quantity,
      };
    }),
    possibleOnepageCheckout: false,
    prices: {
      subtotalExcludingTax: {
        value: _cartPrice?.subtotal_excluding_tax?.value,
        currency: _cartPrice?.subtotal_excluding_tax?.currency,
      },
      subtotalIncludingTax: {
        value: _cartPrice?.subtotal_including_tax?.value,
        currency: _cartPrice?.subtotal_including_tax?.currency,
      },
    },
    subtotalAmount: _cartPrice?.subtotal_including_tax?.value,
    totalQuantity: total_Count,
  };

}

export const addToCartCtx = (
  sku,
  quantityToAdd,
  productData,
  prevCartProducts,
  _cartPrice,
  total_Count,
) => {
  const productEntry = productData[sku];

  // Construct productCtx
  const productCtx = returnProductCtx(productEntry);

  // Construct shoppingCartCtx
  const shoppingCartCtx=returnShoppingCartCtx(productData,_cartPrice,total_Count);

  // Construct changedProductsCtx
  const changedProductsCtx = {
    items: Object.keys(productData)
      .map((key) => productData[key])
      .filter((product) => {
        const sku = product?.product?.sku;
        const prevProduct = prevCartProducts[sku];
        // Include if SKU is new or if quantity has changed
        return !prevProduct || prevProduct.quantity !== product.quantity;
      })
      .map((product) => {
        return {
          canApplyMsrp: false,
          configurableOptions: [],
          formattedPrice: product?.product?.price_range?.minimum_price?.final_price?.value,
          id: product?.product?.id,
          prices: {
            price: {
              value:
                product?.product?.price_range?.minimum_price?.final_price
                  ?.value,
              currency:
                product?.product?.price_range?.minimum_price?.final_price
                  ?.currency,
            },
          },
          product: returnProductCtx(product),
          quantity: product?.quantity,
        };
      }),
  };
  fireAddToCartEvent({ productCtx, shoppingCartCtx, changedProductsCtx });
};

export const placeOrderCtx = (
  appliedCoupons,
  cartProducts,
  cartPrice,
  cartEmail,
  orderId,
  orderType,
  selectedPaymentType,
  selectedShippingMethod,
  total_Count,
) => {
  // Construct shoppingCartCtx
  const shoppingCartCtx=returnShoppingCartCtx(cartProducts,cartPrice,total_Count);

  // Construct orderCtx
  const orderCtx = {
    discountAmount:getTotalDiscountAmount(cartPrice),
    appliedCouponCode: appliedCoupons[0]?.code || "",
    email: cartEmail,
    grandTotal: cartPrice?.grand_total?.value,
    orderId: orderId,
    orderType: orderType,
    paymentMethodCode: selectedPaymentType,
    paymentMethodName: selectedPaymentType,
    payments: [
      {
        paymentMethodCode: selectedPaymentType,
        paymentMethodName: selectedPaymentType,
        total: cartPrice?.grand_total?.value,
      },
    ],
    taxAmount: cartPrice?.subtotal_including_tax?.value - cartPrice?.subtotal_excluding_tax?.value,
    subtotalExcludingTax: cartPrice?.subtotal_excluding_tax?.value,
    subtotalIncludingTax: cartPrice?.subtotal_including_tax?.value,
    shipping: {
      shippingAmount: selectedShippingMethod?.amount?.value || 0.0,
      shippingMethod: selectedShippingMethod?.method_title,
    },
  };

  firePlaceOrderEvent({ shoppingCartCtx, orderCtx });
};

export const returnSearchRequestSentCtx = (payload) => {
  const mockSearchInput = {
    units: [
      {
        searchUnitId: "desktop-search",
        searchRequestId: payload?.request_id || null,
        queryTypes: ["products", "suggestions"],
        phrase: payload?.searchText,
        currentPage: payload?.currentPage,
        pageSize: payload?.pageSize,
        sort:[],
        filter: [
          {
            name: "price",
            operator: "gte", // "from" becomes "gte" (greater than or equal to)
            values: [0.01],
          },
          {
            name: "visibility",
            operator: "in",
            values: [
              "Search",
              "Catalog, Search"
            ],
          }
        ],
      },
    ],
  };
  return mockSearchInput;
};
export const returnMockSearchResults = (products) => {
  return {
    units: [
      {
        searchUnitId: "desktop-search",
        searchRequestId: products?.request_id || null,
        // executionTime: products?.executionTime,
        products: products?.items?.length
          ? products.items.map((item, index) => ({
            name: item?.name,
            sku: item?.sku,
            url: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/${item?.url_key}`,
            imageUrl: item?.thumbnail?.url,
            price: item?.price_range?.minimum_price?.final_price?.value,
            rank: index
          }))
          : [],

        suggestions: products?.suggestions?.length
          ? products?.suggestions?.map((suggestion, index) => ({
            suggestion: suggestion,
            rank: index,
          }))
          : [],
        categories: products?.categories?.length
          ? products?.categories?.map((category, index) => ({
            name: category.name,
            url: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/${category.link}`,
            rank: index,
          }))
          : [],
        page: products?.currentPage,
        perPage: products?.pageSize,
        productCount: products?.items?.length,
        categoryCount: products?.categories?.length,
        suggestionCount: products?.suggestions?.length,
        facets: []
      },
    ],
  };
};

export const searchResponseReceivedCtx = (products) => {
  const mockSearchResults = returnMockSearchResults(products);
  const inputSearchContext = products?.inputSearchContext;
  fireSearchResponseReceivedEvent({ mockSearchResults, inputSearchContext });
};

export const getTotalDiscountAmount = (cartPrice) => {
  const { discounts, gift_promo_discount, specialcoupon_discount_amount } = cartPrice;
  let promotionDiscount=0;
  if(gift_promo_discount){
    promotionDiscount+=gift_promo_discount
  }
  if(specialcoupon_discount_amount){
    promotionDiscount+=specialcoupon_discount_amount
  }
  const totalDiscount =discounts?.reduce(
      (acc, discount) => acc + discount?.amount?.value || 0,
      0,
    ) + gift_promo_discount;
  return totalDiscount || promotionDiscount;
};

export const addSKUInCookies = (sku) => {
  const payload = {
    dateTime: new Date().toISOString(),
    sku: sku,
  };
  let prev = Cookies.get(constants.cookies.searchProductClicked);
  prev = prev ? JSON.parse(prev) : [];
  // Find the index of the existing SKU
  const existingIndex = prev.findIndex((obj) => obj.sku === sku);
  if (existingIndex !== -1) {
    // Remove the existing object
    const [existingObject] = prev.splice(existingIndex, 1);
    // Update timestamp
    existingObject.dateTime = new Date().toISOString();
    // Push the updated object to the end
    prev.push(existingObject);
  } else {
    // Keep only the last 50 interactions
    if (prev.length === 50) {
      prev.shift();
    }
    prev.push(payload);
  }
  // Save updated data back to cookies with a long expiration (e.g., 1 year)
  Cookies.set(constants.cookies.searchProductClicked, JSON.stringify(prev), { expires: 365 });
};

